import {React,useState,useRef,useEffect } from 'react';
import './style.css';
import logo from './assets/logo-undangan1.png'; // Adjust the path to your image
import kiriatas from './assets/kiriatas.png';
import kananatas from './assets/kananatas.png';
import kiribawah from './assets/kiribawah.png';
import kananbawah from './assets/kananbawah.png';
import ornamentengah from './assets/ornamentengah.png';
import hero from './assets/hero2.png'
import basmallah from './assets/basmallah.png'
import orbamenpage2 from './assets/ornamenBISMILLAH.png'
import samplephoto from './assets/sampleimage.jpg'
import BurgerMenu from './BurgerMenu'; // Pastikan path benar
const MobileView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Reference for the menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
    // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false); // Close menu
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const tutupmenu=()=>{
	  setIsOpen(false)
  }

  return (
    <div className="mobile-container">
      <header className="header2"  ref={menuRef}>
	          <div className="logo">
			      <img src={logo} alt="Logo" />
			  </div>
			  <div className="burger-menu" onClick={toggleMenu}>
				<div className={isOpen ? 'line open' : 'line'}></div>
				<div className={isOpen ? 'line open' : 'line'}></div>
				<div className={isOpen ? 'line open' : 'line'}></div>
			  </div>
		
			   <nav className={isOpen ? 'nav-links open' : 'nav-links'}>
			   	   <hr className="separator" />;
				<ul>
				  <li><a onClick={()=>tutupmenu()} href="#home">Home</a></li>
				  <li><a onClick={()=>tutupmenu()} href="#about">About</a></li>
				  <li><a onClick={()=>tutupmenu()} href="#services">Services</a></li>
				  <li><a onClick={()=>tutupmenu()} href="#contact">Contact</a></li>
				  
				</ul>
			  </nav>
	  </header>
      <main id="home" className="main-content">
        <section  className="content2">
		   <div className="kontenmobile" >
		      <div className="mainpagemob">
			      <div className="columnmobornamen">
				     <img className="imageor" src={kiriatas} />
				  </div>
				  <div className="columnmob">
				     <div className="kalimat1">Mohon doa restu</div>
					 <div className="kalimat2">Pada Pernikahan Kami</div>
				  </div>
				  <div className="columnmobornamen">
				     <img className="imageor" src={kananatas} />
				  </div>
			  </div>
			   <div className="mainpagemobhero">
			      <div className="mainpagemobhero1"></div>
				  <div className="mainpagemobhero2">
				        <div className="kotakimghero"> <img className="imgmobhero1" src={ornamentengah} /></div>
				  </div>
				  <div className="mainpagemobhero1"></div>
			  </div>
			  
			
			  <div className="kotakhero2">
			  {/*<img className="imghero" src={hero} />*/}
			  </div>			  
			<div className="kotaknama">
			    <div className="pengantin">Dilan & Milea</div>
			</div>
			  <div className="bagianbawah">
			     <div className="kaki">
				   <div className="bottom-column column-1 ">
						 <img className="imageor" src={kiribawah} />
				   </div>
				  <div className="bottom-column column-2 ">
				      <div className="mainpagemobhero2">
				        <div className="kotakimghero"> <img className="imgmobhero2" src={ornamentengah} /></div>
				      </div>
				  </div>
				  <div className="bottom-column column-3">
				     <img className="imageor" src={kananbawah} />
				  </div>
				 </div>
			  </div>
		   </div>
		</section>		
      </main>
	    <main id="about" className="main-content2">
		 <section  className="content3">
		    <div className="sect2">
			   <div className="BS20">
			   <img className="basmallah" src={basmallah} />
			   </div>
			   <div className="prakata">
				  QS Adz Dzariyaat ayat 49. "Dan segala sesuatu Kami ciptakan berpasang-pasangan 
				  agar kamu mengingat (kebesaran Allah)"
			   </div>
			   <div className="BS21">
			       <img className="BS22" src={orbamenpage2} />
			   </div>
			   <div className="BS23">
			           <div className="BS24">
                      <img className="BS25photo" src={samplephoto} />
					    <div className="BS26btn">Mempelai Wanita</div>
					   </div>
			   </div>
			   <div className="BS27Kotaknama">
			       <div className="BSnamawanita">MILEA MELATI</div>				  
			   </div>
			   <div className="BSkotakprolog">
			       <div className="BSprologwanita"> "Dunia ini adalah perhiasan dan sebaik-baik perhiasannya adalah perempuan yang shalihah."</div>
			   </div>
			    <div className="BS28">
			           <div className="BS29">
                      <img className="BS30photo" src={samplephoto} />
					    <div className="BS31btn">Mempelai Pria</div>
					   </div>
			   </div>
			   <div className="BS32Kotaknamapria">
			       <div className="BSnamapria">DILAN SATRIA</div>				  
			   </div>
			</div>
		 </section>
		</main>
      <footer className="footer">Footer</footer>
    </div>
  );
};

export default MobileView;
