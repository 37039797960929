import logo from './logo.svg';
import './style.css';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import React, { useState, useEffect } from 'react';
function App() {
 const isMobile = window.innerWidth < 768;
  const isTab = window.innerWidth > 768 && window.innerWidth < 1000;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
      {windowWidth < 768 ? <MobileView /> : ( windowWidth > 768 && windowWidth < 1100 ? null : <DesktopView />)}
    </div>
  );

}

export default App;
