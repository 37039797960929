import React from 'react';
import './style.css';
import logo from './assets/logo-undangan1.png'; // Adjust the path to your image

const DesktopView = () => {
  return (
    <div className="desktop-container">
      <header className="header">
	     <nav className="nav">
        <div className="nav-item left">
          <a href="#home">Home</a>
        </div>
        <div className="logo">
            <img src={logo} alt="Logo" />
        </div>
        <div className="nav-item right">
          <a href="#contact">Contact</a>
        </div>
      </nav>
	  </header>
      <main className="main-content">
        <aside className="sidebar">Sidebar</aside>
        <section className="content">Main Content</section>
      </main>
      <footer className="footer">Footer</footer>
    </div>
  );
};

export default DesktopView;
